exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-admin-competitions-tsx": () => import("./../../../src/pages/admin/competitions.tsx" /* webpackChunkName: "component---src-pages-admin-competitions-tsx" */),
  "component---src-pages-admin-index-tsx": () => import("./../../../src/pages/admin/index.tsx" /* webpackChunkName: "component---src-pages-admin-index-tsx" */),
  "component---src-pages-admin-workshops-tsx": () => import("./../../../src/pages/admin/workshops.tsx" /* webpackChunkName: "component---src-pages-admin-workshops-tsx" */),
  "component---src-pages-artists-tsx": () => import("./../../../src/pages/artists.tsx" /* webpackChunkName: "component---src-pages-artists-tsx" */),
  "component---src-pages-competitions-tsx": () => import("./../../../src/pages/competitions.tsx" /* webpackChunkName: "component---src-pages-competitions-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-cookie-policy-tsx": () => import("./../../../src/pages/cookie-policy.tsx" /* webpackChunkName: "component---src-pages-cookie-policy-tsx" */),
  "component---src-pages-history-2018-tsx": () => import("./../../../src/pages/history/2018.tsx" /* webpackChunkName: "component---src-pages-history-2018-tsx" */),
  "component---src-pages-history-2019-tsx": () => import("./../../../src/pages/history/2019.tsx" /* webpackChunkName: "component---src-pages-history-2019-tsx" */),
  "component---src-pages-history-2022-tsx": () => import("./../../../src/pages/history/2022.tsx" /* webpackChunkName: "component---src-pages-history-2022-tsx" */),
  "component---src-pages-history-2023-tsx": () => import("./../../../src/pages/history/2023.tsx" /* webpackChunkName: "component---src-pages-history-2023-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-schedule-tsx": () => import("./../../../src/pages/schedule.tsx" /* webpackChunkName: "component---src-pages-schedule-tsx" */),
  "component---src-pages-speakers-tsx": () => import("./../../../src/pages/speakers.tsx" /* webpackChunkName: "component---src-pages-speakers-tsx" */),
  "component---src-pages-workshops-tsx": () => import("./../../../src/pages/workshops.tsx" /* webpackChunkName: "component---src-pages-workshops-tsx" */),
  "component---src-templates-competition-tsx": () => import("./../../../src/templates/competition.tsx" /* webpackChunkName: "component---src-templates-competition-tsx" */),
  "component---src-templates-workshop-tsx": () => import("./../../../src/templates/workshop.tsx" /* webpackChunkName: "component---src-templates-workshop-tsx" */)
}

